import React, { useState } from 'react';
import {
    Nav, Container, Navbar
} from 'react-bootstrap';
import MenuIcon from '../../assets/Icons/menu.png'
import CloseIcon from '../../assets/Icons/close.png'


const Header = (props) => {
    const [menuIcon, setMenuIcon] = useState(true);
    const changeSideMenuLayout = () => {
        props.toggleSideMenu();
        setMenuIcon(!menuIcon);

    }
    {/* <div className='col-6 '>
                        <i onClick={() => changeSideMenuLayout()} className={menuIcon ? 'material-icons md-24 md-dark text-white ml-256 cursor-pointer' : 'material-icons md-24 md-dark text-white cursor-pointer'} >menu</i>
                    </div> */}

    return (
        <>
            <Nav>
                <Container className='row header-container'>
                    <div className='menu-icon-holder pull-left'>
                        <img className='menu-icon' onClick={() => changeSideMenuLayout()} src={menuIcon?CloseIcon:MenuIcon} />
                    </div> 
                    <div className='text-center'>
                        <Navbar.Brand className='logo-text letter-space'>BharatVerse</Navbar.Brand>
                    </div>

                </Container>
            </Nav>
        </>
        // <>
        // <div class="bv-navbar__container js-bv-navbar headroom headroom--not-bottom headroom--not-top backdrop-blur-sm headroom--pinned header-container">
        //     <div class="bv-navbar__content">
        //         <div class="flex relative items-center">
        //             <div class="absolute left-2/4 -translate-x-2/4">
        //                 <h1 class="bv-logo-text">BharatVerse</h1>
        //             </div>
                    
        //         </div>
        //     </div>
        // </div>
        // </>
    );
};

export default Header;
