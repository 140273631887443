
const getCoordinateInfoWindow = (coordinates, nearestPlace, image) =>{
    return `<div style="max-height: 100px !important;height:100px !important; padding:16px !important">`+
            `<div class="place-name" >${nearestPlace}</div>`+
            `<span><img class="coordinate-icon" src="`+image+`"> <span class="coordinates-label">${coordinates.lat} , ${coordinates.lng} </span></span>`;
}

const getCoordinateUnavailableWindow = (coordinates, nearestPlace, image) =>{
    return `<div style="max-height: 100px !important;height:100px !important; padding:16px !important">`+
            `<div class="place-name" >${nearestPlace}</div>`+
            `<span><img class="coordinate-icon" src="`+image+`"> <span class="coordinates-label">${coordinates.lat} , ${coordinates.lng} </span></span>`;
}

const getMediaInfoWindow = (locationInfo, coordinateIcon) =>{    
    let partner = locationInfo.partner!=null?`<div>`+locationInfo.partner_support_label+` <a target="_blank" href="`+locationInfo.partner_link+`">`+locationInfo.partner+`</a></div>`:'';
    console.log(partner)
    return `<div style="padding:16px !important">`+
            `<div class="place-name" >${locationInfo.title}</div>`+
            `<span><img class="coordinate-icon" src="`+coordinateIcon+`"><span class="coordinates-label">${locationInfo.coordinates.lat} , ${locationInfo.coordinates.lng} </span></span>`+
            `<div style="margin-top:8px;">${locationInfo.description}</div>`+`<br>`+
            partner+`<br>`+
            `<div><a class="btn btn-primary btn-sm active" href="`+window.location.href+'experience/'+locationInfo.slug+`" target="_blank">Experience the Metaverse</a></div><br>`;
}
{/* <div style="padding:16px !important"><iframe title="YouTube video player" type="text/html" width="400px" height="250px" src="'+locationData.link+'" frameborder="0"></iframe></div> */}

module.exports = {
    getCoordinateInfoWindow,
    getMediaInfoWindow
}