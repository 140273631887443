import React, {useEffect, useState} from "react";
import { Card, Button } from 'react-bootstrap';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Autocomplete from "react-google-autocomplete";
import config from '../../config'
import EventBus from "../../utils/EventBus";
import VRLocations from '../../assets/data/vr_locations.json'
import ReservedLocations from '../../assets/data/reserved_locations.json'
import YouTube from 'react-youtube';
import './SideMenu.css'
import image_white from '../../assets/Icons/image_white.png';
import text_white from '../../assets/Icons/text_white.png';
import vr_white from '../../assets/Icons/vr_white.png';
import youtube_white from '../../assets/Icons/youtube_white.png';

const SideMenu = () => {   
    const [locationInfo, setlocationInfo] = useState(null);
    const [experienceMetaverseMenu, setExperienceMetaverseMenu] = useState([])
    useEffect(() => {        
        EventBus.on("location", (data) =>
            onReceiveLocation(data)
        ); 
        setExperienceMetaverseMenu(VRLocations.filter((loc) => loc.isVisibleMenu));
        
        return() => EventBus.remove("location")       
    },[])

    useEffect(() => {
      setAutoScroll();
    }, [experienceMetaverseMenu])

    const onReceiveLocation = (location) => {
        console.log(location)
        setlocationInfo(location)
    }

    const navigateTo = (event, location) => {
        emitCoordinateInfo(event,location)
    }

    const emitCoordinateInfo = (event, data) =>{
        try{
            console.log("emitting")
          EventBus.dispatch(event, data);
        }catch(err){
          console.log(err);
        }
      }

      const setAutoScroll = () => {
        window.$("[data-autoscroll]").autoscroll({  
              interval: 100,          
              handlerIn:null,            
              handlerOut:null            
            });
      }

    return (
        <div className="sidenav p-3">
            <div className="search-bar d-flex mb-3 mt-5">
                {/* <input className="search form-control form-control-lg" placeholder="Search" type="text" />
                <MaterialIcon icon="search" className='material-icons md-24 md-dark search-icon' /> */}
                <div class="searchInputWrapper">
                <Autocomplete
                    className="searchInput"
                    apiKey={config.google_map_key}
                    placeholder={"Search location"}                    
                    onPlaceSelected={(place) => {
                        console.log(place)
                        emitCoordinateInfo("places",place)
                    }}
                    options={{
                        componentRestrictions: { country: "in" },
                    }}
                />
                </div>
            </div>
            {   locationInfo!=null?         
                <Card className="sidenav-card mb-3">
                    <Card.Body>
                        <Card.Title>About this location</Card.Title>
                        <Card.Text>
                            <p className="m-0">
                            <MaterialIcon icon="location_on" color={"#fff"} size={10}/>
                            <span> {locationInfo!=null?""+locationInfo.coordinates.lat+", "+locationInfo.coordinates.lng:""}</span>
                            </p>
                            <p></p>
                            <p className="m-0">{locationInfo!=null?locationInfo.status:""}</p>                            
                        </Card.Text>
                    </Card.Body>
                </Card>
                :
                <div></div>
            }
            <Card className="sidenav-card mb-3 experience-card">
                <Card.Body>
                    <Card.Title className="font-weight-400">Experience the Metaverse</Card.Title>
                    <Card.Text className="mt-3">
                        <div className="vr-locations" data-autoscroll>
                        {
                            experienceMetaverseMenu.map((vrlocation)=>{
                                 let iconName = vrlocation.type=="VR"?vr_white:vrlocation.type=="VIDEO"?youtube_white:vrlocation.type=="IMAGE"?image_white:text_white
                                //
                                return (
                                    <p key={vrlocation.slug} className="vrlocations mv-1"> <span className="d-flex vrlocation-label">
                                        {/* <MaterialIcon icon={iconName} color="white" class="vrlocation-label" size='tiny' /> */}
                                        <img className="side-icon" src={iconName} />
                                        </span> <span onClick={()=>navigateTo("vr",vrlocation)} className="m-0 cursor-pointer">{vrlocation.title}</span></p>
                                )
                            })                        
                        }
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            {/* <Card className="sidenav-card mb-3">
                <Card.Body>
                    <Card.Title>Reserved Locations</Card.Title>
                    <Card.Text>
                        {
                            ReservedLocations.map((vrlocation)=>{
                                return (
                                    <p onClick={()=>navigateTo("reserved",vrlocation)} className="m-0 cursor-pointer text-decoration-underline">{vrlocation.title}</p>                        
                                )
                            })                        
                        }
                    </Card.Text>
                </Card.Body>
            </Card> */}

            {/* <Card className="sidenav-card mb-3"> */}
                {/* <Card.Body className="text-center">
                <MaterialIcon icon="play_arrow" color={'#1971ca'} />
                    <Card.Title>Videos on Bharatverse</Card.Title>
                </Card.Body> */}
                {/* <YouTube videoId="6gDBq8M_JOg" opts={{height: '160',width: '215'}} /> */}
            {/* </Card> */}
            {/* <Card className="sidenav-card mb-3">
                <Card.Body className="text-center">
                    <Card.Title className="m-0">Contact Us</Card.Title>
                </Card.Body>
            </Card> */}
<p className="note">Note: The project is in beta and the map marking may not be perfectly aligned. 
Please submit any discrepancy you notice and we'll get it fixed</p>
    <ul class="mr-0 ml-auto bv-navbar__submenu-wrapper hidden sm:flex social">
   <li>
      <a href="https://www.twitter.com/@bharatverse_xyz" rel="noopener" target="_blank">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 514">
            <g fill="none">
               <path class="icon-bg" d="M504.272 92.549c-4.67-43.672-39.177-78.167-82.85-82.822a1549.353 1549.353 0 00-328.728 0C48.976 14.331 14.408 48.838 9.727 92.549a1549.353 1549.353 0 000 328.727c4.594 43.74 39.12 78.326 82.85 82.996a1541.818 1541.818 0 00328.728 0c43.738-4.609 78.312-39.147 82.967-82.88a1549.353 1549.353 0 000-328.843z"></path>
               <path class="icon-shape" d="M422.207 163.036a137.31 137.31 0 01-27.433 30.138 11.636 11.636 0 00-4.392 9.135v1.076a192.145 192.145 0 01-12.393 66.735 195.607 195.607 0 01-36.451 61.09 180.364 180.364 0 01-59.462 44.568 168.407 168.407 0 01-19.258 7.564 196.887 196.887 0 01-62.08 9.658 189.47 189.47 0 01-95.011-25.688 5.41 5.41 0 01-2.59-6.05 5.265 5.265 0 015.15-3.957h7.97a127.447 127.447 0 0071.186-21.76 69.265 69.265 0 01-54.632-46.894 3.607 3.607 0 014.101-4.684c3.836.73 7.732 1.1 11.637 1.105h1.454c-24.817-11.403-40.603-36.34-40.29-63.65a3.636 3.636 0 015.381-3.113 61.09 61.09 0 0015.71 6.08c-22.36-22.115-26.964-56.54-11.2-83.753a3.578 3.578 0 015.818-.436 180.887 180.887 0 00124.421 66.298h.262a2.56 2.56 0 001.891-.873c.467-.524.69-1.222.611-1.92a74.444 74.444 0 011.629-25.25 65.63 65.63 0 018.727-20.364 66.91 66.91 0 0136.48-27.957 73.661 73.661 0 0121.848-3.345 68.888 68.888 0 0145.643 17.193 8.99 8.99 0 005.818 2.269 8.727 8.727 0 002.095-.291 127.825 127.825 0 0033.716-13.877 3.607 3.607 0 015.295 4.19 68.538 68.538 0 01-18.153 28.887 125.295 125.295 0 0022.051-7.448 3.607 3.607 0 014.451 5.324z"></path>
            </g>
         </svg>
      </a>
   </li>
   <li>
      <a href="https://discord.gg/TAJwe7gbe4" rel="noopener" target="_blank">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 514">
            <g fill="none">
               <path class="icon-bg" d="M504.272 92.549c-4.67-43.672-39.177-78.167-82.85-82.822a1549.353 1549.353 0 00-328.728 0C48.976 14.331 14.408 48.838 9.727 92.549a1549.353 1549.353 0 000 328.727c4.594 43.74 39.12 78.326 82.85 82.996a1541.818 1541.818 0 00328.728 0c43.738-4.609 78.312-39.147 82.967-82.88a1549.353 1549.353 0 000-328.843z"></path>
               <path class="icon-shape" d="M371.344 151.835A272.157 272.157 0 00304.174 131a1.02 1.02 0 00-1.08.51c-2.901 5.16-6.114 11.89-8.364 17.18-25.365-3.797-50.6-3.797-75.443 0-2.251-5.407-5.58-12.02-8.495-17.18a1.06 1.06 0 00-1.08-.51 271.37 271.37 0 00-67.17 20.834.96.96 0 00-.442.38C99.32 216.13 87.598 278.474 93.348 340.045c.025.3.195.589.429.772 28.226 20.729 55.568 33.313 82.402 41.654.43.13.884-.026 1.158-.38 6.347-8.668 12.005-17.808 16.857-27.42a1.045 1.045 0 00-.573-1.453c-8.975-3.405-17.52-7.556-25.742-12.27-.65-.38-.702-1.31-.103-1.755 1.73-1.296 3.46-2.645 5.112-4.007.3-.249.716-.3 1.067-.144 54.006 24.658 112.475 24.658 165.844 0 .352-.17.768-.117 1.08.13a131.681 131.681 0 005.125 4.02c.598.446.559 1.376-.09 1.756a169.103 169.103 0 01-25.756 12.256 1.052 1.052 0 00-.558 1.466c4.955 9.599 10.614 18.739 16.844 27.408.26.367.728.524 1.157.392 26.964-8.34 54.307-20.925 82.533-41.654.247-.183.403-.458.429-.759 6.881-71.183-11.525-133.016-48.791-187.83a.837.837 0 00-.428-.392zM202.26 302.555c-16.26 0-29.657-14.928-29.657-33.261 0-18.333 13.137-33.26 29.657-33.26 16.649 0 29.917 15.058 29.657 33.26 0 18.333-13.138 33.26-29.657 33.26zm109.652 0c-16.259 0-29.657-14.928-29.657-33.261 0-18.333 13.138-33.26 29.657-33.26 16.65 0 29.918 15.058 29.658 33.26 0 18.333-13.007 33.26-29.658 33.26z"></path>
            </g>
         </svg>
      </a>
   </li>
   <li>
      <a href="https://t.me/bharatverse" rel="noopener" target="_blank">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 514">
            <g fill="none">
               <path class="icon-bg" d="M504.272 92.549c-4.67-43.672-39.177-78.167-82.85-82.822a1549.353 1549.353 0 00-328.728 0C48.976 14.331 14.408 48.838 9.727 92.549a1549.353 1549.353 0 000 328.727c4.594 43.74 39.12 78.326 82.85 82.996a1541.818 1541.818 0 00328.728 0c43.738-4.609 78.312-39.147 82.967-82.88a1549.353 1549.353 0 000-328.843z"></path>
               <path class="icon-shape" d="M222.232 299.296l-5.28 74.267c7.554 0 10.826-3.246 14.75-7.142l35.417-33.849 73.39 53.745c13.459 7.501 22.942 3.551 26.572-12.382l48.173-225.726.013-.014c4.27-19.896-7.195-27.677-20.309-22.796L111.803 233.807c-19.325 7.501-19.033 18.274-3.286 23.155l72.392 22.517L349.06 174.263c7.913-5.24 15.109-2.34 9.19 2.9L222.232 299.296z"></path>
            </g>
         </svg>
      </a>
   </li>
</ul>
        </div>
    )
}

export default SideMenu;