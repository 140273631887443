import '../../App.css';
import './Map.css';
import React, { useEffect, useState } from "react";
import CurrentLocationIcon from '../../assets/Icons/currentLocation';
import VRMarkerIcon from '../../assets/Icons/vr_marker.png'
import YTMarkerIcon from '../../assets/Icons/youtube_marker.png'
import LocMarkerIcon from '../../assets/Icons/location_black.png'
import BlockedMarkerIcon from '../../assets/Icons/blocked_marker.png'
import LogoIndia from '../../assets/Icons/logo_india.png'
import EventBus from "../../utils/EventBus";
import ReservedLocations from '../../assets/data/reserved_locations.json'
import VRLocations from '../../assets/data/vr_locations.json'
import { getCoordinateInfoWindow, getMediaInfoWindow } from './utils'
import swal from 'sweetalert';

function App() {

  let address
  let info
  let infoWindow
  let map;
  let currenLatLong = { lat: 12.8350887, lng: 77.6927409 };
  let drawingManager;
  let geocoder;
  let mapType = 'hybrid';//roadmap
  let lastInfoWindow = null;

  const onReceiveVRLocation = (location) => {
    try {
      showVRLocation(location)
    } catch (err) {
      console.log(err);
    }
  }

  const onReceivePlacesLocation = (location) => {
    try {
      showPlacesLocation(location.geometry.location)
    } catch (err) {
      console.log(err);
    }
  }

  const onReceiveReservedLocation = (location) => {
    try {
      showPlacesLocation(location.coordinates, "reserved")
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setGoogleMap();
    currentLocation()
    initVRListener()
    loadVRLocations()
    swal("Current beta edition is best viewed in desktop/laptop. Mobile friendly version is coming soon..");
    return () => { EventBus.remove("vr"); EventBus.remove("places"); EventBus.remove("reserved") }
  }, []);



  const initVRListener = () => {
    EventBus.on("vr", (data) =>
      onReceiveVRLocation(data)
    );
    EventBus.on("places", (data) =>
      onReceivePlacesLocation(data)

    );
    EventBus.on("reserved", (data) =>
      onReceiveReservedLocation(data)
    );
  }

  const setGoogleMap = async () => {
    try {
      var bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(7.273990, 62.520330),
        new window.google.maps.LatLng(38.5785991, 99.2522173)
      );
      map = new window.google.maps.Map(document.getElementById("map"), {
        center: currenLatLong,
        zoom: 5,
        restriction: {
          latLngBounds: bounds,
          strictBounds: true
        },
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        mapTypeId: mapType,
        // draggableCursor: 'cursor: url("https://downloads.totallyfreecursors.com/cursor_files/india.ani"),url("https://downloads.totallyfreecursors.com/thumbnails/india.gif"), auto;'
      });            

      let gridData;
      map.addListener('bounds_changed', function () {
        const zoom = map.getZoom();
        const loadFeatures = zoom > 17;

        if (loadFeatures) {
          var ne = map.getBounds().getNorthEast();
          var sw = map.getBounds().getSouthWest();

          window.what3words.api
            .gridSectionGeoJson({
              southwest: {
                lat: sw.lat(), lng: sw.lng()
              },
              northeast: {
                lat: ne.lat(), lng: ne.lng()
              }
            }).then(function (data) {
              console.log("GeoJSON: ", data)
              if (gridData !== undefined) {
                for (var i = 0; i < gridData.length; i++) {
                  map.data.remove(gridData[i]);
                }
              }
              gridData = map.data.addGeoJson(data);
            }).catch(console.error);
        }


        map.data.setStyle({
          visible: loadFeatures,
          strokeColor: '#CCD5EA',
          strokeWeight: 0.9,
          clickable: true,
        });
      });

      map.addListener('click', async (event) => {
        //if(map.getZoom() >= 17){
        let isIndia = await getLocationFromCoordinate(event.latLng.toJSON())
        console.log("isIndia", isIndia)
        let locationData = {}
        if (isIndia) {
          get3wa(event.latLng.toJSON(), map, true)
          locationData = {
            status: "Ready for sale",
            reserved: false,
            isOutside: false,
            coordinates: event.latLng.toJSON()
          }
          //emitCoordinateInfo(locationData)
        } else {
          locationData = {
            status: "Service unavailable outside India",
            reserved: true,
            isOutside: true,
            coordinates: event.latLng.toJSON()
          }
          get3wa(event.latLng.toJSON(), map, false)
          locationData = {
            status: "Ready for sale",
            reserved: false,
            isOutside: false,
            coordinates: event.latLng.toJSON()
          }
          //emitCoordinateInfo(locationData)
        }
        // }
      })

      const square = [{ "lat": 51.5772648410943, "lng": 43.548401908866225 }, { "lat": -11.759507004362465, "lng": 44.603089408866225 }, { "lat": -11.587361555768512, "lng": 117.55230815886623 }, { "lat": 52.01212996631522, "lng": 117.20074565886623 }]
      const hole = ReservedLocations.at(ReservedLocations.length - 1).path


      let hole_ = new window.google.maps.Polygon({
        paths: [square, hole],
        strokeColor: "#AB4631",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#B7876D",
        fillOpacity: 0.65
      });
      hole_.setMap(map)

      // loadBayofBengal()
      
    } catch (err) {
      console.log(err)
    }
  }

  const loadBayofBengal = () => {
    let bob = new window.google.maps.Marker({
      map,
      draggable: false,
      animation: window.google.maps.Animation.DROP,
      position: { lat: 10.076428, lng: 87.818777 },
      icon: LogoIndia
    });
    bob.setZIndex(799);
  }

  const getLocationFromCoordinate = async (coordinate) => {
    geocoder = new window.google.maps.Geocoder();
    let latlng = new window.google.maps.LatLng(coordinate.lat, coordinate.lng);
    let status = await geocoder.geocode({ 'latLng': latlng })
    if (status.results[0]) {
      let add = status.results[0].formatted_address;
      let value = add.split(",");

      let count = value.length;
      let country = value[count - 1];
      console.log("Country ", country)
      return country.trim() === "India"
    } else {
      return false
    }
  }

  const setDrawingManager = async () => {
    drawingManager = new window.google.maps.drawing.DrawingManager();
    drawingManager.setOptions({
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
        drawingModes: ['polygon']
      }
    });
    drawingManager.setMap(map);

    window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
      console.log(JSON.stringify(polygon.getPath().getArray().map((coord) => {
        return { lat: coord.lat(), lng: coord.lng() }
      })))
      // let infowindow = new window.google.maps.InfoWindow();
      // var area = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
      // infowindow.setContent("selected area="+area.toFixed(2)+" sq meters");
      // infowindow.setPosition(polygon.getPath().getAt(0));
      // infowindow.open(map);
    })
  }

  const closeLastInfoWindow = () => {
    try {
      if (lastInfoWindow != null) {
        lastInfoWindow.close()
      }
    } catch (err) {

    }
  }

  const get3wa = (coordinates, map, indiaCheck) => {
    let visible = false
    if (address) address.setMap(null)
    if (info) info.setMap(null)
    window.what3words.api.convertTo3wa({ lat: coordinates.lat, lng: coordinates.lng }, 'en')
      .then(function (response) {
        let { map: m, square, words, nearestPlace, country } = response
        console.log(response)
        if (country == "IN" || country == 'XH' || country == 'XJ' || indiaCheck) {
          address = new window.google.maps.Rectangle({
            bounds: new window.google.maps.LatLngBounds(
              square.southwest,
              square.northeast,
            ),
            strokeColor: "#39A2FF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#48BFFE",
            fillOpacity: 0.75
          })
          closeLastInfoWindow()
          info = new window.google.maps.InfoWindow({
            content: getCoordinateInfoWindow(coordinates, nearestPlace, LocMarkerIcon),
            position: coordinates,
            maxHeight: 100
          })
          lastInfoWindow = info;
          address.addListener('click', (e) => {
            if (visible) info.close()
            else {
            }
            visible = !visible
          })
          address.setMap(map)
          info.setMap(map)
          visible = true
        } else {
          closeLastInfoWindow()
          info = new window.google.maps.InfoWindow({
            content: getCoordinateInfoWindow(coordinates, "Unavailable outside India", LocMarkerIcon),
            position: coordinates,
            maxHeight: 100
          })

          info.setMap(map)
          lastInfoWindow = info
        }
      });

  }

  const showVRLocation = (locationData, type) => {
    let vrMarker = new window.google.maps.Marker({
      map,
      draggable: false,
      animation: window.google.maps.Animation.DROP,
      position: locationData.coordinates,
      icon: locationData.type == "VR" ? VRMarkerIcon : YTMarkerIcon
    });
    if (locationData.path) {
      const reserved = new window.google.maps.Polygon({
        paths: locationData.path,
        strokeColor: "#9bd2ec",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#9bd2ec",
        fillOpacity: 0.35
      });
      reserved.setMap(map)
    }

    vrMarker.addListener('click', () => {
      map.setZoom(20);
      map.setCenter(vrMarker.getPosition())
      //window.open(locationData.link)
      //if(locationData.type == "VIDEO"){
      closeLastInfoWindow()
      let infowindow = new window.google.maps.InfoWindow({
        content: getMediaInfoWindow(locationData, LocMarkerIcon)
      });
      lastInfoWindow = infowindow
      infowindow.open(map, vrMarker);

      // }
    })
    if (type != 'onload') {
      map.setZoom(25)
      map.setCenter(locationData.coordinates);
      // get3wa(locationData.coordinates, map)
      closeLastInfoWindow()
      let infowindow = new window.google.maps.InfoWindow({
        content: getMediaInfoWindow(locationData, LocMarkerIcon)
      });
      infowindow.open(map, vrMarker);
      lastInfoWindow = infowindow

    }

  }

  const loadReservedLocations = () => {
    ReservedLocations.map((location) => {
      showReservedLocation(location)
    })
  }

  const loadVRLocations = () => {    
    VRLocations.map((location)=>{
      if(location.isVisibleMenu){
        showVRLocation(location, 'onload')
      }
      
    })
  }

  const showReservedLocation = (locationData) => {
    if (locationData.type != "out") {
      return;
    }
    const reserved = new window.google.maps.Polygon({
      paths: locationData.path,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true,
    });
    let blockedMarker = new window.google.maps.Marker({
      map,
      draggable: false,
      animation: window.google.maps.Animation.DROP,
      position: locationData.coordinates,
      icon: BlockedMarkerIcon
    });
    blockedMarker.addListener("click", () => {
      map.setZoom(18);
      map.setCenter(blockedMarker.getPosition());
    });
    reserved.setMap(map);
  }

  const showPlacesLocation = (locationData, type) => {
    let options = {
      map,
      draggable: false,
      animation: window.google.maps.Animation.DROP,
      position: locationData,
    }
    if (type == 'reserved') {
      options['icon'] = BlockedMarkerIcon
    }
    new window.google.maps.Marker(options);
    map.setZoom(17)
    map.setCenter(locationData);

  }

  const currentLocation = () => {

    const locationButton = document.getElementById("current-location-button");
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton);

    locationButton.addEventListener("click", () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            closeLastInfoWindow()
            infoWindow = new window.google.maps.Marker({
              map,
              draggable: false,
              animation: window.google.maps.Animation.DROP,
              position: pos,
            });
            map.setZoom(25);
            map.setCenter(pos);
            lastInfoWindow = infoWindow
          },
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          }
        );
      } else {
        // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, map.getCenter());
      }
    });

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      closeLastInfoWindow()
      infoWindow.open(map);
      lastInfoWindow = infoWindow
    }
  }

  const emitCoordinateInfo = (data) => {
    try {
      EventBus.dispatch("location", data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="App">
      <div id="map"></div>
      <div id="current-location-button" className="custom-map-control-button">
        <CurrentLocationIcon />
      </div>
    </div>
  );
}

export default App;

