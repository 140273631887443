import '../../App.css';
import './Map.css';
import React ,{ useEffect, useState } from "react";
import MaterialIcon, {colorPalette} from 'material-icons-react';
// import MyLocationIcon from '@mui/icons-material/MyLocation';
// import {} from './api/index'
import CurrentLocationIcon from '../../assets/Icons/currentLocation';
import VRMarkerIcon from '../../assets/Icons/vr_marker.png'
import YTMarkerIcon from '../../assets/Icons/youtube_marker.png'
import BlockedMarkerIcon from '../../assets/Icons/blocked_marker.png'
import EventBus from "../../utils/EventBus";
// import India from '../../assets/data/india_Boundary.geojson'
import ReservedLocations from '../../assets/data/reserved_locations.json'
import VRLocations from '../../assets/data/vr_locations.json'
import IndiaKML from '../../assets/data/india_kml.kml'

function App() {

  let address
  let info
  let infoWindow
  let map;
  let currenLatLong = { lat: 12.8350887, lng: 77.6927409 };
  let drawingManager;
  let geocoder;


  const [indianJSON, setIndianJSON] = useState(null);

  const onReceiveVRLocation = (location) => {
    try{
      console.log("onReceiveLocation",location)
      showVRLocation(location)
      //setlocationInfo(location)
    }catch(err){
      console.log(err);
    }
  }

  const onReceivePlacesLocation = (location) => {
    try{
      console.log("onReceivePlacesLocation",location, location.geometry.location)
      showPlacesLocation(location.geometry.location)
      //setlocationInfo(location)
    }catch(err){
      console.log(err);
    }
  }

  const onReceiveReservedLocation = (location) => {
    try{
      console.log("onReceiveReservedLocation",location.coordinates)
      showPlacesLocation(location.coordinates, "reserved")
      // showReservedLocation(location)
      // map.setZoom(20)
      //setlocationInfo(location)
    }catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    // getGridSystem()
    setGoogleMap();
    currentLocation()
    initVRListener()
    loadReservedLocations()
    //loadVRLocations()
    return() =>{ EventBus.remove("vr"); EventBus.remove("places"); EventBus.remove("reserved")}
  }, []);



  const initVRListener = () => {
      console.log("initVRListener")
      EventBus.on("vr", (data) =>
        onReceiveVRLocation(data)
    ); 
    EventBus.on("places", (data) =>
      onReceivePlacesLocation(data)
      
    );
    EventBus.on("reserved", (data) =>
    onReceiveReservedLocation(data)
  ); 
  }

  const setGoogleMap = async () => {
    try {
      var bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(7.273990, 62.520330),
        new window.google.maps.LatLng(38.5785991,99.2522173)
    );
      map = new window.google.maps.Map(document.getElementById("map"), {
        //12.8350887,77.6927409 - Synclovis
        center: currenLatLong,
        zoom: 5,
        // restriction: {
        //   latLngBounds: bounds,
        //   strictBounds: true
        // },
      });      
      // setDrawingManager();

      


      let gridData;      
      map.addListener('bounds_changed', function () {
        const zoom = map.getZoom();
        const loadFeatures = zoom > 17;

        if (loadFeatures) {
          var ne = map.getBounds().getNorthEast();
          var sw = map.getBounds().getSouthWest();

          window.what3words.api
            .gridSectionGeoJson({
              southwest: {
                lat: sw.lat(), lng: sw.lng()
              },
              northeast: {
                lat: ne.lat(), lng: ne.lng()
              }
            }).then(function (data) {
              console.log("GeoJSON: ", data)
              if (gridData !== undefined) {
                for (var i = 0; i < gridData.length; i++) {
                  map.data.remove(gridData[i]);
                }
              }
              gridData = map.data.addGeoJson(data);
            }).catch(console.error);
        }
        

        map.data.setStyle({
          visible: loadFeatures,
          strokeColor: '#CCD5EA',
          strokeWeight: 0.9,
          clickable: true,
          // fillColor:"#00bcd4",
          //   strokeColor:"#00bcd4",
        });
      });

      map.addListener('click', async(event) => {
        //if(map.getZoom() >= 17){
            let isIndia = await getLocationFromCoordinate(event.latLng.toJSON())
            console.log("isIndia",isIndia)
            let locationData = {} 
            if(isIndia){
              get3wa(event.latLng.toJSON(), map)       
              locationData = {
                status:"Ready for sale",
                reserved:false,
                isOutside:false,            
                coordinates:event.latLng.toJSON()
              } 
              //emitCoordinateInfo(locationData)
            }else{
              locationData = {
                status:"Service unavailable outside India",
                reserved:true,
                isOutside:true,            
                coordinates:event.latLng.toJSON()
              }
              get3wa(event.latLng.toJSON(), map, true)       
              locationData = {
                status:"Ready for sale",
                reserved:false,
                isOutside:false,            
                coordinates:event.latLng.toJSON()
              } 
              //emitCoordinateInfo(locationData)
            }
       // }
      })
      // console.log(India)

      
      
    } catch (err) {
      console.log(err)
    }
  }

  const getLocationFromCoordinate = async(coordinate) => {
    geocoder = new window.google.maps.Geocoder();
    let latlng = new window.google.maps.LatLng(coordinate.lat, coordinate.lng);
    let status = await geocoder.geocode({'latLng': latlng})
    console.log("gcoder:: ",status)
    //if (status == window.google.maps.GeocoderStatus.OK) {
      if (status.results[0]) {
          let add = status.results[0].formatted_address ;
          let value=add.split(",");

          let count=value.length;
          let country=value[count-1];
          console.log("Country ",country)
          return country.trim()==="India"
      }else{
        return false
      }
    //}
    
  }

  const setDrawingManager = async() => {
    drawingManager = new window.google.maps.drawing.DrawingManager();
      drawingManager.setOptions({
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          drawingModes: ['polygon']
        }
      });
      drawingManager.setMap(map);

      window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
        console.log(JSON.stringify(polygon.getPath().getArray().map((coord)=>{
          return {lat:coord.lat(), lng:coord.lng()}
        })))
        // let infowindow = new window.google.maps.InfoWindow();
        // var area = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
        // infowindow.setContent("selected area="+area.toFixed(2)+" sq meters");
        // infowindow.setPosition(polygon.getPath().getAt(0));
        // infowindow.open(map);
      })
  }

  const get3wa = (coordinates, map, indiaCheck) => {
    let visible = false
    if (address) address.setMap(null)
    if (info) info.setMap(null)
    window.what3words.api.convertTo3wa({ lat: coordinates.lat, lng: coordinates.lng }, 'en')
      .then(function (response) {
        console.log("[convertTo3wa]", response);
        let { map: m, square, words, nearestPlace, country } = response
        if(country == "IN" || country == 'XH' || country == 'XJ'){
            address = new window.google.maps.Rectangle({
              bounds: new window.google.maps.LatLngBounds(
                square.southwest,
                square.northeast,
              )
            })
            // info = new window.google.maps.InfoWindow({
            //   content: `<a href="${m}" target="_new"><b>${words}</b></a><br><small>${nearestPlace}</small>`,
            //   position: coordinates,
            // })
            address.addListener('click', (e) => {
              if (visible) info.close()
              else {
                //info.setPosition(e.latLng.toJSON())
                //info.open(map)
              }
              visible = !visible
            })
            //info.addListener('closeclick', () => visible = false)
            address.setMap(map)
            //info.setMap(map)
            visible = true
        }
      });

  }

  const showVRLocation = (locationData, type) => {
    let vrMarker = new window.google.maps.Marker({
      map,
      draggable: false,
      animation: window.google.maps.Animation.DROP,
      position: locationData.coordinates,
      icon: locationData.type=="VR"?VRMarkerIcon:YTMarkerIcon 
    });      
    
    vrMarker.addListener('click', ()=> {
      map.setZoom(20);
      map.setCenter(vrMarker.getPosition())      
      //window.open(locationData.link)
      //if(locationData.type == "VIDEO"){
          let infowindow = new window.google.maps.InfoWindow({
            content: '<iframe title="YouTube video player" type="text/html" width="400px" height="250px" src="'+locationData.link+'" frameborder="0"></iframe>'
          });
          infowindow.open(map, vrMarker);
     // }
    })
    if(type != 'onload'){          
      map.setZoom(25)
      map.setCenter(locationData.coordinates);
      // get3wa(locationData.coordinates, map)
      let infowindow = new window.google.maps.InfoWindow({
        content: '<iframe title="YouTube video player" type="text/html" width="400px" height="280px"  src="'+locationData.link+'" frameborder="0"></iframe>'
      });
      infowindow.open(map, vrMarker);
    }
    
  }

  const loadReservedLocations = () => {    
    ReservedLocations.map((location)=>{
      showReservedLocation(location)
    })
  }

  const loadVRLocations = () => {    
    VRLocations.map((location)=>{
      showVRLocation(location, 'onload')
    })
  }

  const showReservedLocation = (locationData) => {
    if(locationData.type != "out"){
      return;
    }
    const reserved = new window.google.maps.Polygon({
      paths: locationData.path,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true,
    });
    
    let indian_path = locationData.path

    window.google.maps.event.addListener(reserved.getPath(), 'insert_at', function(index, obj) {
      //polygon object: yourPolygon
      indian_path = (reserved.getPath().getArray().map((coord)=>{
        return {lat:coord.lat(), lng:coord.lng()}
      }))
      // console.log("INSERTAT",JSON.stringify(reserved.getPath().getArray().map((coord)=>{
      //   return {lat:coord.lat(), lng:coord.lng()}
      // })))
    });
    window.google.maps.event.addListener(reserved.getPath(), 'set_at', function(index, obj) {
            //polygon object: yourPolygon
            indian_path = (reserved.getPath().getArray().map((coord)=>{
              return {lat:coord.lat(), lng:coord.lng()}
            }))

            // console.log("SETAT",JSON.stringify(reserved.getPath().getArray().map((coord)=>{
            //   return {lat:coord.lat(), lng:coord.lng()}
            // })))
    });

    reserved.addListener('click', ()=> {
      console.log(indian_path)
      let json = JSON.stringify(indian_path.map((coord)=>{
        return {lat:coord.lat, lng:coord.lng}
      }));
      console.log("FINAL_COORS",json);
      //navigator.clipboard.writeText(json);
      
      alert("JSON updated")
      setIndianJSON(json)
    })
    reserved.setMap(map);
  }

  const showPlacesLocation = (locationData, type) => {
    let options = {
      map,
      draggable: false,
      animation: window.google.maps.Animation.DROP,
      position: locationData,
      //icon: VRMarkerIcon
    }
    if(type == 'reserved'){
      options['icon'] = BlockedMarkerIcon
    }
    new window.google.maps.Marker(options);  
    map.setZoom(17)              
    map.setCenter(locationData);
   
    //get3wa(locationData, map)
  }

  const currentLocation = () => {
    
    const locationButton = document.getElementById("current-location-button");
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton);

    locationButton.addEventListener("click", () => {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            infoWindow = new window.google.maps.Marker({
              map,
              draggable: false,
              animation: window.google.maps.Animation.DROP,
              position: pos,
            });                        
            // get3wa(pos, map)
            map.setZoom(25);
            map.setCenter(pos);
            let emittedData = {
              coordinates:pos,
              status:"Your location"
            }
            // emitCoordinateInfo(emittedData)
          },
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          }
        );
      } else {
        // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, map.getCenter());
      }
    });

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: The Geolocation service failed."
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    }
  }

  const emitCoordinateInfo = (data) =>{
    try{
      EventBus.dispatch("location", data);
    }catch(err){
      console.log(err);
    }
  }

  return (
    <div className="App">
      <div id="map"></div>
      <div id="current-location-button" className="custom-map-control-button">
        <CurrentLocationIcon />         
      </div>      
      <div>
      <textarea value={indianJSON} className='textarea-btn'/>      
      </div>
    </div>
  );
}

export default App;

