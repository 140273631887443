import '../../App.css';
import React ,{ useEffect, useState } from "react";
import VRLocations from '../../assets/data/vr_locations.json'


function Experience() {

    const [contentLink, setContentLink] = useState(null);
    const [isContentEmpty, setContentEmpty] = useState(false);
    useEffect(() => {
        loadMedia()
      }, []);
    
      const loadMedia = async()=>{
        try{
            const lastSegment = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
            console.log(lastSegment)
            let vrLocation = fetchMediaInfo(lastSegment)
            console.log("vrLo",vrLocation)
            if(vrLocation){
                setContentLink(vrLocation.link)
            }else{
                setContentEmpty(true)
            }
        }catch(err){
            console.log(err)
        }
      }

      const fetchMediaInfo = (slug) =>{
       let location = VRLocations.find((location)=>location.slug==slug)
       return location
      }

  return (
    <div className="App">
      <div className='overlay'>
      {
        !isContentEmpty?
        <iframe title="Bharatverse" type="text/html" 
        width="100%" height="100%" src={contentLink} frameborder="0"></iframe> :
        <div className='row'>
          <div className='col'>
            <h6 className='no-content'>No Content Available</h6>
          </div>
        </div>
        
      } 
    </div>   
    </div>
  );
}

export default Experience;

