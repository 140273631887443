import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, useMapEvent, useMap } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import what3words, { fetchTransport } from "@what3words/api";
import L from 'leaflet';

const apiKey = 'PLY6BMOU';
const config = {
    host: 'https://api.what3words.com',
    apiVersion: 'v3',
}
const transport = fetchTransport();
const w3wService = what3words(apiKey, config, { transport });
w3wService.gridSection()

const MapLeaflet = () => {

    function Grid() {
        const map = useMap()

        const drawGrid = () => {
            const zoom = map.getZoom();
            const loadFeatures = zoom > 17;

            if (loadFeatures) {
                var ne = map.getBounds().getNorthEast();
                var sw = map.getBounds().getSouthWest();
                w3wService.gridSection({
                    boundingBox: {
                        southwest: {
                            lat: sw.lat,
                            lng: sw.lng
                        },
                        northeast: {
                            lat: ne.lat,
                            lng: ne.lng
                        }
                    },
                    format: 'geojson'
                }).then(res => {
                    if (typeof L.GridLayer !== "undefined") {
                        map.removeLayer(L.GridLayer);
                    }
                    L.GridLayer = new L.GeoJSON(res, {
                        style: function () {
                            return {
                                color: "#777",
                                stroke: true,
                                weight: 0.5
                            };
                        }
                    }).addTo(map);
                }).catch(console.error);
            }
            else {
                if (typeof L.GridLayer !== "undefined") {
                  map.removeLayer(L.GridLayer);
                }
              }
        }

        drawGrid()

        useMapEvent('move', () => {
            drawGrid()
        })

        return null
    }

    return (
        <MapContainer center={[51.505, -0.09]} zoom={18} scrollWheelZoom={true}
            style={{ height: "700px", width: "100%" }}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxNativeZoom={18}
                maxZoom={24}
            />
            {/* <Marker position={[51.505, -0.09]}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker> */}
            <Grid />
        </MapContainer>
    )
}

export default MapLeaflet;