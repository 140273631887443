import logo from './logo.svg';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import MapLeaflet from './components/MapLeaflet';
import Map from './components/Map'
import Experience from './components/Experience'
import AdminMap from './components/AdminMap'
import IndiaMap from './components/India'
import Header from './components/Header/Header';
import SideMenu from './components/SideMenu/SideMenu';
import { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


function App() {
  const [showSideMenu, setShowSideMenu] = useState(true);
  const isProduction = true;

  const firebaseConfig = {
    apiKey: "AIzaSyCdcaKK1hOLbKmj2pgNudSwrjMSqbSVCEI",
    authDomain: "xbv-359114.firebaseapp.com",
    projectId: "xbv-359114",
    storageBucket: "xbv-359114.appspot.com",
    messagingSenderId: "678059660958",
    appId: "1:678059660958:web:6ab661487c954726108d8f",
    measurementId: "G-CRFLKR5EEX"
  };

  if (isProduction) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }
  
  return (
    <BrowserRouter>
    <Header showSideMenu={showSideMenu}
    toggleSideMenu={()=>{setShowSideMenu(!showSideMenu)}} />
    {showSideMenu ? 
    <SideMenu /> : null }
      <Routes>
        <Route path={"/"} element={<Map />} />
        <Route path={"/experience/:id"} element={<Experience />} />
        {!isProduction?<Route path={"/draw"} element={<AdminMap />} />:null}
        {!isProduction?<Route path={"/india"} element={<IndiaMap />} />:null}
        {!isProduction?<Route path={"/leaflet"} element={<MapLeaflet />} />:null}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
